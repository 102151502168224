.logo {
	height: 88px;
	display: flex;
	align-items: center;
	justify-content: center;
	h1 {
		color: #fff;
		font-size: 26px;
		display: flex;
		align-items: center;
		justify-content: center;
		.icon {
			margin-right: 10px;
		}
	}
	h1.small {
		color: #00ccff;
		.icon {
			margin-right: 0;
		}
	}
}

@primary-color: #1DA57A;